import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://4e1c44384d3ef6d29721da938000b379@o4506821182357504.ingest.us.sentry.io/4506821185306624",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});